.margin-left-100 {
  margin-left: 100px;
}
.margin-right-100 {
  margin-right: 100px;
}
.margin-top-100 {
  margin-top: 100px;
}
.margin-bottom-100 {
  margin-bottom: 100px;
}
.margin-100 {
  margin: 100px;
}
.margin-left-95 {
  margin-left: 95px;
}
.margin-right-95 {
  margin-right: 95px;
}
.margin-top-95 {
  margin-top: 95px;
}
.margin-bottom-95 {
  margin-bottom: 95px;
}
.margin-95 {
  margin: 95px;
}
.margin-left-90 {
  margin-left: 90px;
}
.margin-right-90 {
  margin-right: 90px;
}
.margin-top-90 {
  margin-top: 90px;
}
.margin-bottom-90 {
  margin-bottom: 90px;
}
.margin-90 {
  margin: 90px;
}
.margin-left-85 {
  margin-left: 85px;
}
.margin-right-85 {
  margin-right: 85px;
}
.margin-top-85 {
  margin-top: 85px;
}
.margin-bottom-85 {
  margin-bottom: 85px;
}
.margin-85 {
  margin: 85px;
}
.margin-left-80 {
  margin-left: 80px;
}
.margin-right-80 {
  margin-right: 80px;
}
.margin-top-80 {
  margin-top: 80px;
}
.margin-bottom-80 {
  margin-bottom: 80px;
}
.margin-80 {
  margin: 80px;
}
.margin-left-75 {
  margin-left: 75px;
}
.margin-right-75 {
  margin-right: 75px;
}
.margin-top-75 {
  margin-top: 75px;
}
.margin-bottom-75 {
  margin-bottom: 75px;
}
.margin-75 {
  margin: 75px;
}
.margin-left-70 {
  margin-left: 70px;
}
.margin-right-70 {
  margin-right: 70px;
}
.margin-top-70 {
  margin-top: 70px;
}
.margin-bottom-70 {
  margin-bottom: 70px;
}
.margin-70 {
  margin: 70px;
}
.margin-left-65 {
  margin-left: 65px;
}
.margin-right-65 {
  margin-right: 65px;
}
.margin-top-65 {
  margin-top: 65px;
}
.margin-bottom-65 {
  margin-bottom: 65px;
}
.margin-65 {
  margin: 65px;
}
.margin-left-60 {
  margin-left: 60px;
}
.margin-right-60 {
  margin-right: 60px;
}
.margin-top-60 {
  margin-top: 60px;
}
.margin-bottom-60 {
  margin-bottom: 60px;
}
.margin-60 {
  margin: 60px;
}
.margin-left-55 {
  margin-left: 55px;
}
.margin-right-55 {
  margin-right: 55px;
}
.margin-top-55 {
  margin-top: 55px;
}
.margin-bottom-55 {
  margin-bottom: 55px;
}
.margin-55 {
  margin: 55px;
}
.margin-left-50 {
  margin-left: 50px;
}
.margin-right-50 {
  margin-right: 50px;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-bottom-50 {
  margin-bottom: 50px;
}
.margin-50 {
  margin: 50px;
}
.margin-left-45 {
  margin-left: 45px;
}
.margin-right-45 {
  margin-right: 45px;
}
.margin-top-45 {
  margin-top: 45px;
}
.margin-bottom-45 {
  margin-bottom: 45px;
}
.margin-45 {
  margin: 45px;
}
.margin-left-40 {
  margin-left: 40px;
}
.margin-right-40 {
  margin-right: 40px;
}
.margin-top-40 {
  margin-top: 40px;
}
.margin-bottom-40 {
  margin-bottom: 40px;
}
.margin-40 {
  margin: 40px;
}
.margin-left-35 {
  margin-left: 35px;
}
.margin-right-35 {
  margin-right: 35px;
}
.margin-top-35 {
  margin-top: 35px;
}
.margin-bottom-35 {
  margin-bottom: 35px;
}
.margin-35 {
  margin: 35px;
}
.margin-left-30 {
  margin-left: 30px;
}
.margin-right-30 {
  margin-right: 30px;
}
.margin-top-30 {
  margin-top: 30px;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.margin-30 {
  margin: 30px;
}
.margin-left-25 {
  margin-left: 25px;
}
.margin-right-25 {
  margin-right: 25px;
}
.margin-top-25 {
  margin-top: 25px;
}
.margin-bottom-25 {
  margin-bottom: 25px;
}
.margin-25 {
  margin: 25px;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-right-20 {
  margin-right: 20px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
.margin-20 {
  margin: 20px;
}
.margin-left-15 {
  margin-left: 15px;
}
.margin-right-15 {
  margin-right: 15px;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
.margin-15 {
  margin: 15px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-bottom-10 {
  margin-bottom: 10px;
}
.margin-10 {
  margin: 10px;
}
.margin-left-5 {
  margin-left: 5px;
}
.margin-right-5 {
  margin-right: 5px;
}
.margin-top-5 {
  margin-top: 5px;
}
.margin-bottom-5 {
  margin-bottom: 5px;
}
.margin-5 {
  margin: 5px;
}
.margin-left-0 {
  margin-left: 0px;
}
.margin-right-0 {
  margin-right: 0px;
}
.margin-top-0 {
  margin-top: 0px;
}
.margin-bottom-0 {
  margin-bottom: 0px;
}
.margin-0 {
  margin: 0px;
}
.margin-0 {
  margin: 0 !important;
}
.padding-0 {
  padding: 0 !important;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.btn-rounded {
  border-radius: 30px !important;
  padding: 0  !important;
  font-weight: 600  !important;
  border: 2px solid #888;
  width: 30px;
  height: 30px;
  color: #fff  !important;
  font-size: 10px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  outline: none  !important;
}
.btn-rounded i.fa {
  margin: 0;
  padding: 0;
}
.btn-rounded span {
  display: none;
}
.btn-rounded:focus {
  border-color: #00aeef;
  border-radius: 20px;
  outline: none;
}
.btn-rounded:hover {
  border-color: #00aeef;
  background-color: #00aeef;
  color: white;
  border-radius: 20px;
}
.btn-rounded.active {
  background-color: #00aeef;
  color: white;
}
.background-login-register {
  font-family: 'Montserrat', sans-serif;
  position: fixed;
  background: #333;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  z-index: -200;
  top: 0;
  left: 0;
}
.header-center {
  position: absolute;
  width: 100%;
  top: 15px;
}
.header-center .logo-container {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.content-login {
  position: relative;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.content-register {
  position: relative;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
#playground-container {
  height: 500px;
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
}
.main {
  margin: 50px 15px;
}
h1.title {
  font-size: 50px;
  font-family: 'Passion One', cursive;
  font-weight: 400;
}
hr {
  width: 10%;
  color: #fff;
}
.form-group {
  margin-bottom: 15px;
}
label {
  margin-bottom: 15px;
}
input,
input::-webkit-input-placeholder {
  font-size: 11px;
  padding-top: 3px;
}
.main-login {
  background-color: #fff;
  /* shadows and rounded borders */
  border-radius: 2px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.form-control {
  height: auto !important;
  padding: 8px 12px !important;
}
.input-group {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.21) !important;
}
#button {
  border: 1px solid #ccc;
  margin-top: 28px;
  padding: 6px 12px;
  color: #666;
  text-shadow: 0 1px #fff;
  cursor: pointer;
  border-radius: 3px 3px;
  box-shadow: 0 1px #fff inset, 0 1px #ddd;
  background: #f5f5f5;
  background: -moz-linear-gradient(top, #f5f5f5 0%, #eeeeee 100%);
  background: linear-gradient(top, #f5f5f5 0%, #eeeeee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f5f5f5', endColorstr='#eeeeee', GradientType=0);
}
.main-center {
  margin: 0 auto;
  max-width: 400px;
  padding: 10px 40px;
  background: #047fca;
  color: #FFF;
  text-shadow: none;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.31);
}
span.input-group-addon i {
  color: #009edf;
  font-size: 17px;
}
.login-button {
  font-size: 18px;
  text-align: center;
  margin: 10px;
  width: 120px;
}
.register-button {
  font-size: 18px;
  text-align: center;
  margin: 10px;
  width: 120px;
}
.register-alone-button {
  font-size: 18px;
  text-align: center;
  margin: auto;
  width: 120px;
  position: relative;
}
.header-container {
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  -moz-transition-duration: 1s;
       transition-duration: 1s;
  -moz-transition-delay: 0s;
       transition-delay: 0s;
  -moz-transition-property: all;
  transition-property: all;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
  z-index: 100;
}
.fix-navbar {
  font-family: 'Montserrat', sans-serif;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: inline-block;
  z-index: 50000;
  -moz-transition-duration: 1s;
       transition-duration: 1s;
  -moz-transition-delay: 0s;
       transition-delay: 0s;
  -moz-transition-property: all;
  transition-property: all;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
}
.line-header-content {
  display: block;
  margin: auto;
  margin-top: 20px;
}
.faq {
  font-size: 25px;
  padding-top: 0;
  padding-bottom: 5px;
}
.content-now-playing {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  position: relative;
  margin-top: 90px;
  color: white;
}
.content-now-playing .slideshow-scrollbar {
  position: absolute;
  top: -10px;
  width: 100%;
  z-index: 1100;
}
.content-now-playing .slideshow-scrollbar img {
  -webkit-filter: brightness(0.4);
  filter: brightness(0.4);
  border: none;
  display: inline-block;
  outline: 1px solid white;
}
.content-now-playing .slideshow-scrollbar img.cycle-pager-active {
  -webkit-filter: brightness(1);
  filter: brightness(1);
  outline: 2px solid #047fca;
}
@media screen and (max-width: 1440px) {
  .content-now-playing .control-sly {
    position: absolute;
    top: 100px;
    width: 100%;
    display: none;
  }
}
@media screen and (min-width: 1440px) {
  .content-now-playing .control-sly {
    position: absolute;
    top: 100px;
    width: 100%;
    display: block;
  }
}
.content-now-playing .btn-prev {
  left: -150px;
  top: 0;
  border-radius: 0;
  background: url("/images/assets/left-arrow-now-playing.png");
  border: 0;
  outline: 0;
  width: 72px;
  height: 72px;
  position: absolute;
  display: inline-block;
}
.content-now-playing .btn-next {
  right: -150px;
  top: 0;
  border-radius: 0;
  background: url("/images/assets/right-arrow-now-playing.png");
  border: 0;
  outline: 0;
  width: 72px;
  height: 72px;
  position: absolute;
  display: inline-block;
}
.content-now-playing .btn-prev:hover {
  border: 0;
  border-radius: 0;
  outline: 0;
  background: url("/images/assets/left-arrow-now-playing-hover.png");
}
.content-now-playing .btn-next:hover {
  border: 0;
  border-radius: 0;
  outline: 0;
  background: url("/images/assets/right-arrow-now-playing-hover.png");
}
.content-coming-soon {
  font-family: 'Open Sans', sans-serif;
  position: relative;
  color: white;
  font-size: 15px;
  font-weight: 300;
  width: 100%;
  z-index: 1005;
  height: 820px;
  background: rgba(0, 0, 0, 0.8);
}
.content-coming-soon .parallax-container {
  min-height: 820px;
  background: transparent;
}
.content-coming-soon .btn-prev {
  left: -150px;
  top: 0;
  border-radius: 0;
  background: url("/images/assets/left-arrow-now-playing.png");
  border: 0;
  outline: 0;
  width: 72px;
  height: 72px;
  position: absolute;
  display: inline-block;
}
.content-coming-soon .btn-next {
  right: -150px;
  top: 0;
  border-radius: 0;
  background: url("/images/assets/right-arrow-now-playing.png");
  border: 0;
  outline: 0;
  width: 72px;
  height: 72px;
  position: absolute;
  display: inline-block;
}
@media screen and (max-width: 1440px) {
  .content-coming-soon .control-sly {
    position: absolute;
    top: 100px;
    width: 100%;
    display: none;
  }
}
@media screen and (min-width: 1440px) {
  .content-coming-soon .control-sly {
    position: absolute;
    top: 100px;
    width: 100%;
    display: block;
  }
}
.content-coming-soon .btn-prev:hover {
  border: 0;
  border-radius: 0;
  outline: 0;
  background: url("/images/assets/left-arrow-now-playing-hover.png");
}
.content-coming-soon .btn-next:hover {
  border: 0;
  border-radius: 0;
  outline: 0;
  background: url("/images/assets/right-arrow-now-playing-hover.png");
}
.content-features {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background: white;
  z-index: 1000;
  position: relative;
  left: 0;
  right: 0;
  height: 400px;
}
.content-features .container .row .col-md-4 {
  height: 150px;
}
.content-features .container .row .col-md-4 .logo-features {
  margin-top: 30px;
  background-size: 100% 100%;
  position: relative;
  width: 200px;
  height: 43px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.content-features .container .row .col-md-4 .logo-features-dlp {
  margin-top: 0;
  background-size: 100% 100%;
  position: relative;
  width: 200px;
  height: 100px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.content-box-office {
  font-family: 'Open Sans', sans-serif;
  position: relative;
  color: white;
  font-size: 15px;
  font-weight: 300;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 1001;
  height: 650px;
}
.location-list {
  list-style-type: none;
  width: 560px;
}
.location-list li:hover {
  background: #000000;
  cursor: pointer;
  -moz-transition-duration: 0.5s;
       transition-duration: 0.5s;
}
.location-list li {
  position: relative;
  display: inline-block;
  padding: 15px;
  width: 100%;
  height: 50%;
  background: rgba(0, 0, 0, 0.8);
}
.location-list li .image {
  width: 38%;
  height: 70px;
  -webkit-align-content: center;
      -ms-flex-line-pack: center;
          align-content: center;
  position: relative;
}
.location-list li .image img {
  width: 100%;
  height: 50px;
  position: absolute;
  top: 5px;
}
.location-list li .address {
  width: 60%;
  position: absolute;
  right: 0;
  top: 5px;
}
.location-list li .address h6.title {
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  margin: 5px 0 5px 0;
}
.location-list li .address p {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  text-align: center;
  color: #ccc;
}
.images {
  display: block;
  width: 500px;
  margin: 0 auto;
  -webkit-box-reflect: below 0 -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(0.9, transparent), to(white));
}
.info .images-logo {
  margin: 10px;
  width: 200px;
}
.info .title-info {
  font-family: 'Open Sans', sans-serif;
  text-align: left;
  color: white ;
  font-size: 15px;
  font-weight: bold;
  padding-left: 15px;
}
.info .address-info {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  color: #ccc;
  text-align: left;
  padding-left: 15px;
}
.content-footer {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background: black;
  background-size: 100%;
  z-index: 1000;
  position: relative;
  color: white;
  left: 0;
  right: 0;
}
.content-footer .line-title {
  display: block;
  font-weight: 300;
}
.content-footer .container table.footer-table {
  color: white;
  width: 100%;
  font-weight: 100;
}
.content-footer .container table.footer-table > tbody > tr > td {
  padding: 5px 15px;
  font-size: 14px;
  position: relative;
}
.content-footer .container .btn-send {
  margin-left: 330px;
  background: #00aeef;
  font-weight: 900;
  font-size: 12pt;
  border: 1px;
  border-radius: 0;
  display: inline;
  padding: 8px 20px;
}
.content-playing {
  font-family: 'Open Sans', sans-serif;
  margin-top: 20px;
}
.content-playing .btn-prev {
  left: -100px;
  top: 0;
  border-radius: 0;
  background: url("/images/assets/left-arrow-now-playing.png");
  border: 0;
  outline: 0;
  width: 72px;
  height: 72px;
  position: absolute;
  display: inline-block;
}
.content-playing .btn-next {
  right: -100px;
  top: 0;
  border-radius: 0;
  background: url("/images/assets/right-arrow-now-playing.png");
  border: 0;
  outline: 0;
  width: 72px;
  height: 72px;
  position: absolute;
  display: inline-block;
}
@media screen and (max-width: 1440px) {
  .content-playing .control-sly {
    position: absolute;
    top: 100px;
    width: 100%;
    display: none;
  }
}
@media screen and (min-width: 1440px) {
  .content-playing .control-sly {
    position: absolute;
    top: 100px;
    width: 100%;
    display: block;
  }
}
.content-playing .btn-prev:hover {
  border: 0;
  border-radius: 0;
  outline: 0;
  background: url("/images/assets/left-arrow-now-playing-hover.png");
}
.content-playing .btn-next:hover {
  border: 0;
  border-radius: 0;
  outline: 0;
  background: url("/images/assets/right-arrow-now-playing-hover.png");
}
.content-schedule {
  width: 100%;
  z-index: 1000;
  position: relative;
  height: 600px;
  color: white;
}
.content-schedule .container .row {
  width: 100%;
}
.content-schedule .container .row .col-md-6.schedule {
  z-index: 1400;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  width: 36%;
  margin: 20px;
  padding: 10px;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  color: white;
  left: 150px;
  height: 520px;
}
.content-schedule .container .row .schedule .schedule-error {
  margin-top: 180px;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-size: 25pt;
  position: absolute;
}
.content-schedule .container .col-md-6.schedule h1.banner {
  font-size: 25px;
  font-weight: bolder;
  padding: 10px;
  color: white;
  margin: 0;
  background: #047fca;
  width: 100%;
  text-shadow: #000 0 1px 1px;
}
.schedule-container {
  position: absolute;
  top: 90px;
  width: 100%;
  margin-bottom: 20px;
}
.schedule-container .container {
  background: none !important;
}
.schedule-container .container .schedule {
  border: none;
  background: none !important;
}
.schedule {
  padding: 10px;
}
.schedule .bx-wrapper {
  background: none;
  box-shadow: none;
  border: none;
}
.schedule .slide {
  position: relative;
}
.schedule .slide .bx-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.schedule .slide .slider-controls {
  position: absolute;
  top: 7px;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 100;
}
.schedule .slide .slider-controls .left-control {
  width: 30px;
  height: 30px;
  float: left;
}
.schedule .slide .slider-controls .right-control {
  width: 30px;
  height: 30px;
  float: right;
}
.schedule .header {
  color: #fff;
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  font-family: sans-serif;
  font-weight: bolder;
  margin-bottom: 5px;
}
.schedule .content {
  display: block;
}
.schedule .content ul.content-schedule {
  height: auto;
  list-style: none;
  background: none;
  padding: 0;
  margin: 0;
  font-family: sans-serif;
}
.schedule .content ul.content-schedule li {
  overflow: hidden;
}
.schedule .content ul.content-schedule li .left-side {
  float: left;
  display: inline-block;
  width: 50px;
  height: 50px;
  font-size: 40px;
  text-align: center;
  font-weight: 700;
  line-height: 50px;
}
.schedule .content ul.content-schedule li .right-side {
  width: 290px;
  float: left;
  display: inline-block;
}
.schedule .content ul.content-schedule li .right-side .title-row {
  overflow: hidden;
  position: relative;
}
.schedule .content ul.content-schedule li .right-side .time-row {
  overflow: hidden;
}
.schedule .content ul.content-schedule li .right-side .time-row .time {
  float: left;
  margin: 0 2px;
}
.schedule .content ul.content-schedule li .right-side .time-row .time:first-child {
  margin-left: 0;
}
.schedule .content ul.content-schedule li .right-side .time-row .time:after {
  content: " - ";
}
.schedule .content ul.content-schedule li .right-side .time-row .time:last-child:after {
  content: "";
}
.schedule .header-date {
  overflow: hidden;
}
.schedule .header-date .header-date-text {
  display: inline-block;
  line-height: 30px;
  margin: 5px;
  font-size: 15px;
  color: white;
}
.schedule .header-date .button-list {
  display: inline-block;
  margin: 5px 0;
  float: right;
}
.content-schedule .col-md-6 .schedule-list {
  list-style: none;
  margin: 0;
  padding: 0;
  height: 420px;
}
.content-schedule .col-md-6 .schedule-list li {
  position: relative;
  overflow: hidden;
  display: table;
  width: 100%;
}
.content-schedule .col-md-6 .schedule-list li .left {
  width: 40px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0;
}
.content-schedule .col-md-6 .schedule-list li .left .studio {
  font-size: 50px;
  font-family: Oswald, arial, Georgia, serif;
}
.content-schedule .col-md-6 .schedule-list li .right {
  display: table-cell;
  vertical-align: middle;
}
.content-schedule .col-md-6 .schedule-list li .right .film {
  font-family: Oswald, arial, Georgia, serif;
  text-transform: uppercase;
  padding-bottom: 0;
  font-size: 12pt;
}
.content-schedule .col-md-6 .schedule-list li .right .time {
  display: inline-block;
  margin-right: 8px;
}
.content-schedule-article {
  width: 100%;
  background: transparent;
  z-index: 1000;
  position: absolute;
  top: 700px;
  left: 0;
  right: 0;
  height: 600px;
  color: white;
}
.content-schedule-article .container .row {
  width: 100%;
}
.content-schedule-article .container .row .col-md-6.schedule {
  z-index: 1400;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  width: 36%;
  margin: 20px;
  padding: 10px;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  color: white;
  left: 150px;
  height: 520px;
}
.content-schedule-article .container .col-md-6.schedule h1.banner {
  font-size: 25px;
  font-weight: bolder;
  padding: 10px;
  color: white;
  margin: 0;
  background: #047fca;
  text-shadow: #000 0 1px 1px;
}
.content-schedule-article .col-md-6 .schedule-list {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 420px;
}
.content-schedule-article .col-md-6 .schedule-list li {
  position: relative;
  overflow: hidden;
  display: table;
  width: 100%;
}
.content-schedule-article .col-md-6 .schedule-list li .left {
  width: 40px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding: 0;
}
.content-schedule-article .col-md-6 .schedule-list li .left .studio {
  font-size: 50px;
  font-family: Oswald, arial, Georgia, serif;
}
.content-schedule-article .col-md-6 .schedule-list li .right {
  display: table-cell;
  vertical-align: middle;
}
.content-schedule-article .col-md-6 .schedule-list li .right .film {
  font-family: Oswald, arial, Georgia, serif;
  text-transform: uppercase;
  padding-bottom: 0;
  font-size: 12pt;
}
.content-schedule-article .col-md-6 .schedule-list li .right .time {
  display: inline-block;
  margin-right: 8px;
}
.content-twitter {
  width: 100%;
  background: none;
  z-index: 1015;
  position: relative;
  height: 800px;
  color: white;
}
.content-twitter .parallax-container {
  min-height: 800px;
  background: transparent;
}
.content-twitter .container .row {
  width: 100%;
}
.content-twitter .container .row .twitter {
  left: 70px;
  right: 0;
  padding: 0;
  position: relative;
}
.content-twitter .container .row .col-md-4 .twitter-blank {
  position: relative;
  width: 380px;
  height: 500px;
  background: #292F33;
  border-radius: 4px;
  margin: 50px;
}
.content-about-us {
  width: 100%;
  background: transparent;
  z-index: 1000;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  height: 1900px;
  color: white;
}
.content-about-us .container {
  z-index: 1000;
  height: 100%;
  width: 75%;
}
.content-about-us .container .row {
  width: 100%;
}
.content-about-us .container .row .logo-content {
  display: block;
  width: 500px ;
  margin: auto;
  margin-top: 35px;
  margin-bottom: 50px;
}
.content-about-us .container .row .col-md-12.images {
  z-index: 1400;
  background: transparent;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  width: 100%;
  color: white;
  left: 15px;
  right: 0;
  height: 650px;
}
.content-about-us .container .row .col-md-12.images .parallax-container {
  min-height: 650px;
  background: transparent;
}
.content-about-us .parallax-slider {
  -webkit-filter: brightness(50%);
  /* Safari */
  filter: brightness(50%);
  z-index: 2000;
}
.content-history {
  width: 100%;
  z-index: 1000;
  position: absolute;
  top: 1180px;
  left: 0;
  right: 0;
  color: white;
}
.content-gallery {
  width: 100%;
  z-index: 1000;
  position: absolute;
  top: 1380px;
  left: 0;
  right: 0;
  color: white;
}
.content-box-office-about {
  font-family: 'Open Sans', sans-serif;
  position: absolute;
  top: 1880px;
  bottom: 900px;
  color: white;
  font-size: 15px;
  font-weight: 300;
  width: 100%;
  left: 0;
  right: 0;
  background: white;
  z-index: 1001;
}
.content-box-office-about .container {
  display: block;
  margin: auto;
}
.content-box-office-about .container .row .label {
  top: 0;
}
.content-box-office-about .container .col-md-3 .cinema {
  background: url('../../images/assets/cinema-background.png') no-repeat;
  position: absolute;
  width: 500px;
  height: 380px;
  padding: 10px;
  top: 0;
  display: inline-block;
}
.content-box-office-about .container .col-md-3 .cinema.movimax-sarinah {
  margin-left: 60px ;
}
.content-box-office-about .container .col-md-3 .cinema.movimax-dinoyo {
  margin-left: 285px ;
}
.content-box-office-about .container .cinema .cinema-container {
  display: block;
  top: 70px;
  position: absolute;
  width: 100%;
}
.content-box-office-about .container .cinema .cinema-container .cinema-content {
  color: white;
}
.content-box-office-about .container .cinema .cinema-container table.cinema-table {
  width: 100%;
}
.content-box-office-about .container .cinema .cinema-container table.cinema-table > tbody > tr > td {
  padding: 10px 15px;
  font-size: 14px;
  position: relative;
  color: white;
}
.content-box-office-about .container .col-md-3 .cinema img {
  left: 120px;
  position: relative;
  display: inline-block;
  width: 50%;
}
.content-box-office-about .container .col-md-3 .cinema .maps {
  width: 450px;
  height: 200px;
}
.content-features-about {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background: transparent;
  z-index: 1000;
  top: 970px;
  position: absolute;
  left: 0;
  right: 0;
  color: white;
  height: 400px;
}
.content-features-about .container .row .col-md-4 {
  height: 150px;
}
.content-features-about .container .row .col-md-4 .logo-features {
  margin-top: 65px;
  background-size: 100% 100%;
  position: absolute;
  width: 260px;
  height: 80px;
  display: block;
  margin-left: 50px;
  margin-right: auto;
}
.content-features-about .container .row .col-md-4 .logo-features-dlp {
  margin-top: 0;
  background-size: 100% 100%;
  position: absolute;
  width: 300px;
  height: 200px;
  display: block;
  margin-left: 22px;
}
.img-logo {
  width: 250px;
  height: 65px;
  margin: 0 auto;
  display: block;
}
.content-footer-about {
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  background: black;
  background-size: 100%;
  z-index: 1000;
  position: absolute;
  color: white;
  top: 2500px;
  height: 160px;
}
.content-footer-about .line-title {
  display: block;
  font-weight: 300;
}
.content-footer-about .container table.footer-table {
  color: white;
  width: 100%;
  font-weight: 100;
}
.content-footer-about .container table.footer-table > tbody > tr > td {
  padding: 5px 15px;
  font-size: 14px;
  position: relative;
}
.content-footer-about .container .btn-send {
  margin-left: 330px;
  background: #00aeef;
  font-weight: 900;
  font-size: 12pt;
  border: 1px;
  border-radius: 0;
  display: inline;
  padding: 8px 20px;
}
.content-footer-about .container .row .col-md-12 .image-footer {
  position: absolute;
  left: 450px;
  top: 30px;
}
/* kelas button pada tab */
.btn-tab {
  border-bottom: 5px solid #f44336;
  color: white;
  padding: 15px 20px 15px 20px;
  display: inline-block;
  text-align: center;
}
/* kelas artikel pada halaman article.html */
.article-container {
  display: block;
  top: 120px;
  position: absolute;
  width: 100%;
  z-index: 2000;
}
.tooltip-templates {
  display: none;
}
.article-container .container {
  padding: 15px;
}
.article-container .article-back {
  background-color: rgba(0, 0, 0, 0.6);
}
.article-container .article-cover {
  position: relative;
  padding-left: 15px;
}
.article-container .article-cover > .image-cover {
  width: 180px;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 236px;
  display: block;
  background-color: #000;
}
.article-container .article-cover > .cover-mask {
  position: absolute;
  top: 0;
  left: 0;
}
.article-container .article-cover > .player-fullscreen-hover {
  position: absolute;
  opacity: 0;
  background: url(/images/assets/cinema-background.png);
  background-size: 100% 100%;
  height: 236px;
  top: 0;
  left: 15px;
  right: 15px;
  width: 180px;
}
.article-container .article-cover > .player-fullscreen-hover > .btn-play-fullscreen {
  position: absolute;
  background: url("/images/assets/btn-play.png");
  height: 100px;
  width: 100px;
  display: block;
  top: 30%;
  left: 23%;
  border: 0;
  border-radius: 0;
  outline: 0;
}
.article-container .article-cover:hover > .player-fullscreen-hover {
  opacity: 1;
}
.article-container .article-content {
  color: white;
}
.article-container .article-content .share-button .facebook-icons {
  position: absolute;
  font-size: 25px;
  top: 5px;
  right: 11px;
}
.article-container .article-content .share-button .twitter-icons {
  position: absolute;
  font-size: 25px;
  top: 6px;
  right: 6px;
}
.article-container .article-content .movie-tags {
  list-style: none;
  padding: 0;
  margin: 0;
}
.article-container .article-content .movie-tags li {
  padding: 0 10px;
  display: inline-block;
  border-right: 1px solid white;
}
.article-container .article-content .movie-tags li:first-child {
  padding-left: 0;
}
.article-container .article-content .movie-tags li:last-child {
  border: none;
}
.article-container .article-content .cast-label {
  margin: 0 0 5px 0;
}
.article-container .article-content .cast-catalog {
  list-style: none;
  padding: 0;
  margin: 0;
}
.article-container .article-content .cast-catalog li {
  display: inline-block;
}
.article-container .article-content .cast-catalog li:first-child {
  margin-left: 0;
}
.article-container .article-content .cast-catalog li .artist-link:hover > img {
  cursor: pointer;
  opacity: 1;
}
.article-container .article-content .cast-catalog li .artist-link > img {
  width: 50px;
  height: 60px;
  background: black;
  border: none;
  opacity: 0.8;
}
.article-container .article-content .article-title {
  text-transform: uppercase;
  font-size: 29px;
}
.article-container .article-content .article-paragraph {
  padding-top: 0px;
  line-height: 30px;
  height: 150px;
  margin-top: 5px;
}
.article-container .article-content .article-spec-table {
  color: white;
  width: 100%;
  background: none;
}
.article-container .article-content .article-spec-table tbody > tr > td {
  border-bottom: 1px solid #ddd;
  padding: 10px 15px;
  font-size: 14px;
  position: relative;
  background: none;
}
.article-container .article-content .article-spec-table tbody > tr > td:first-child > td {
  border-top: 1px solid #ddd;
}
.article-container .article-content .article-spec-table tbody > tr > td:first-child > td:last-child:after {
  content: url('/images/assets/circle.png');
  position: absolute;
  top: -10px;
  right: 0;
}
.article-container .article-content .article-spec-table tbody > tr > td:first-child > td:last-child:before {
  content: url('/images/assets/circle.png');
  position: absolute;
  bottom: -10px;
  right: 0;
}
.article-container .article-content .article-spec-table tbody > tr > td:last-child:after {
  content: url('/images/assets/circle.png');
  position: absolute;
  bottom: -10px;
  right: 0;
}
.article-container .article-content .article-schedule {
  padding: 15px;
}
.table-purchase {
  border: 0;
  border-radius: 0;
  margin-top: 20px ;
  margin-left: 5px ;
}
.table-purchase .purchase-studio {
  margin-top: -2.5px;
  margin-left: -2.5px;
}
.table-purchase .purchase-time {
  font-size: 10pt;
  margin-left: 5px;
}
.button-normal {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
ul.button-list {
  list-style: none;
  padding: 0;
  margin: 0 0 5px 0;
  display: block;
}
ul.button-list li {
  display: inline-block;
  margin: 2px 5px;
}
ul.button-list li:first-child {
  margin-left: 0;
}
ul.button-list li.header {
  width: 150px;
}
ul.button-list li a {
  padding: 4px 15px;
  border: 1px solid #ddd;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  font-family: sans-serif;
}
ul.button-list li a:hover {
  text-decoration: none;
}
.button-modal-studio {
  background: #047fca;
  border: 0;
  padding: 5px 10px;
  font-size: 10pt;
  margin-left: 5px;
  margin-right: 5px;
}
.button-modal-studio:hover {
  background: #fc9230;
  border: 0;
  outline: 0;
  padding: 5px 10px;
  color: white;
}
.button-modal-studio:focus {
  border: 0;
  outline: 0;
  padding: 5px 10px;
  color: white;
}
.modal-studio {
  top: 15%;
  position: fixed;
  color: white;
}
.modal-studio-content {
  color: white;
  background: rgba(0, 0, 0, 0.9);
  border: none;
  overflow: hidden;
}
.modal-studio-h3 {
  color: white;
  padding-top: 0;
  padding-bottom: 0;
}
.modal-studio .modal-dialog .modal-content .modal-header {
  position: relative;
  display: block;
}
h6.address {
  padding: 0;
  margin: 0;
}
.btn-select-studio {
  border-radius: 0;
  border: none;
  background: #047fca;
  font-size: 12pt;
}
.btn-select-studio:hover {
  border-radius: 0;
  border: none;
  background: #fc9230;
}
.studio-circle-o {
  margin-top: 2px;
  font-size: 25pt;
}
.button-modal-datepicker {
  background: #047fca;
  border: 0;
  padding: 5px 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.button-modal-datepicker:hover {
  background: #fc9230;
  border: 0;
  outline: 0;
  padding: 5px 10px;
  color: white;
}
.button-modal-datepicker:focus {
  border: 0;
  outline: 0;
  padding: 5px 10px;
  color: white;
}
.modal-datepicker {
  top: 15%;
  position: fixed;
  color: white;
}
.modal-datepicker-background {
  background: rgba(255, 255, 255, 0.9);
}
.modal-datepicker .modal-dialog .modal-content .modal-header {
  position: relative;
  display: block;
}
.modal-datepicker .modal-dialog .modal-content .modal-body .datepicker {
  width: 100%;
  margin-top: -10px;
  margin-left: 15px;
}
.fullscreen-manager-logo {
  left: 25px;
  bottom: 25px;
  display: inline-block;
  position: fixed;
  opacity: 0.5;
  filter: alpha(opacity=50);
  z-index: 100;
}
.fullscreen-manager-logo:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.fullscreen-manager-logo .btn-fullscreen-logo {
  background: url("/images/assets/logo.png");
  width: 250px;
  height: 62px;
  border: 0;
  border-radius: 0;
  outline: 0;
}
.fullscreen-manager {
  z-index: 100;
  display: inline-block;
  height: 120px;
  bottom: 25px;
  right: 25px;
  position: fixed;
  color: white;
  opacity: 0.5;
  filter: alpha(opacity=50);
  padding: 0 8px;
  background-color: rgba(100, 100, 100, 0.5);
}
.fullscreen-manager:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.fullscreen-manager .back-set {
  position: absolute;
}
.fullscreen-manager .back-set .content-detail {
  position: absolute;
}
.fullscreen-manager button {
  background: none;
  border: none;
  border-radius: 0;
  color: white;
  outline: 0;
  padding: 5px 10px;
  text-align: center;
}
.fullscreen-manager button:hover {
  background: #fc9230;
  border: none ;
  border-radius: 5px;
  color: white;
  outline: 0;
}
.fullscreen-manager .content-cover {
  position: absolute;
  left: -105px;
  top: 0px;
}
.fullscreen-manager .content-cover > .image-cover {
  width: 90px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 120px;
}
.slideshow-container {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: black;
  background-size: 100% 100%;
  height: 100%;
  z-index: -200;
}
.slideshow-container .cycle-slideshow {
  width: 100%;
  height: 100%;
  position: fixed;
}
.cycle-slideshow img {
  width: 100%;
  height: 100%;
}
.cycle-pager {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 2;
}
#alt-caption {
  position: absolute;
  bottom: 10px;
  width: 100%;
  color: white;
  text-align: center;
}
.slideshow-header {
  font-family: 'Open Sans', sans-serif;
  position: absolute;
  bottom: 50px;
  width: 100%;
  color: white;
  z-index: 10000;
}
.slideshow-header .no-caption .caption-decoration {
  display: none;
}
.slideshow-header .container #alt-caption {
  position: relative;
  color: white;
  text-align: left;
}
#alt-caption p {
  text-align: justify;
}
.slideshow-header .container .cycle-slideshow {
  position: absolute;
  z-index: 1050;
  width: 75%;
  height: 100%;
}
.button-slideshow-header {
  margin-top: 0;
}
.info-slideshow-header {
  font-weight: bolder;
  position: absolute;
  color: white;
  top: 450px;
}
.info-slideshow-header .info {
  background: rgba(255, 255, 255, 0.9);
  position: absolute;
  padding: 10px;
  font-size: 14px;
  opacity: 0;
  z-index: 1;
  -moz-transition-delay: 0s;
       transition-delay: 0s;
  -moz-transition-duration: 0.6s;
       transition-duration: 0.6s;
  -moz-transition-property: all;
  transition-property: all;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
  height: 100%;
}
.tint {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: 100% 100%;
  height: 100%;
  z-index: 1;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+28,000000+28,000000+37,000000+100&0.7+0,0.1+16,0+25,0.1+25,0.5+57,1+91 */
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.1) 16%, rgba(0, 0, 0, 0.1) 25%, rgba(0, 0, 0, 0.14) 28%, rgba(0, 0, 0, 0.25) 37%, rgba(0, 0, 0, 0.5) 57%, #000000 91%, #000000 100%);
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.1) 16%, rgba(0, 0, 0, 0.1) 25%, rgba(0, 0, 0, 0.14) 28%, rgba(0, 0, 0, 0.25) 37%, rgba(0, 0, 0, 0.5) 57%, #000000 91%, #000000 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3000000', endColorstr='#000000', GradientType=0);
  /* IE6-9 */
}
.background-container {
  font-family: 'Montserrat', sans-serif;
  position: fixed;
  background: url("/images/assets/black-pattern.jpg");
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  z-index: -200;
  top: 0;
  left: 0;
}
.background-container .container .background-body {
  background: white;
  z-index: 1000;
  position: absolute;
  top: 80px;
  height: 100%;
  width: 68%;
  border: 2px darkgrey outset;
  border-radius: 5px;
}
.widget-upcoming {
  height: 670px;
  width: 110%;
  margin-top: 30px;
  margin-left: -20px;
  overflow: hidden;
}
.brick {
  width: 190px;
  height: 300px;
  position: relative;
  margin: 3px;
}
.brick .brick-image {
  position: absolute;
  margin: auto;
  background-size: 100% 100%;
  width: 190px;
  height: 300px;
}
.brick .brick-info {
  color: white;
  position: absolute;
  background: rgba(0, 0, 0, 0.9);
  background-size: 100% 100%;
  opacity: 0;
  width: 190px;
  height: 300px;
  padding: 10px;
  /* Safari */
  -moz-transition-duration: .5s;
       transition-duration: .5s;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
}
.brick .brick-info .title-md {
  font-size: .8em;
}
.brick .brick-info .desc {
  font-size: 13px;
  color: white;
  padding: 0 5px;
}
.brick .brick-info .click-more {
  color: white;
  font-weight: bold;
  font-size: 15px;
  padding-top: 30px;
}
.brick .brick-info-button {
  position: relative;
  width: 50%;
  height: 50%;
  top: 27%;
  left: 12%;
}
.brick .brick-info-button .btn-config-watch-trailer {
  width: 172px;
  height: 40px;
  background: url('/images/assets/rounded-rectangle-movie-selected.png') no-repeat;
  border: none;
  color: white;
  display: inline-block;
  margin: 2px;
  line-height: 25px;
}
.brick .brick-info-button .btn-config-buy-ticket {
  width: 172px;
  height: 40px;
  background: url('/images/assets/buy-ticket.png');
  border: none;
  color: white;
  margin: 2px;
  display: inline-block;
  line-height: 25px;
}
.brick .brick-info-button .glyphicon {
  color: white;
}
.brick .brick-info-button .btn-config-watch-trailer:hover {
  background: url('/images/assets/rounded-rectangle-movie-selected-hover.png') no-repeat;
  /* Safari */
  -moz-transition-duration: .5s;
       transition-duration: .5s;
}
.brick .brick-info-button .btn-config-buy-ticket:hover {
  background: url('/images/assets/buy-ticket-hover.png') no-repeat;
  /* Safari */
  -moz-transition-duration: .5s;
       transition-duration: .5s;
}
.brick .brick-info-button .btn-config-watch-trailer:focus {
  border: none;
  border-radius: 0;
  outline: none;
}
.brick .brick-info-button .btn-config-buy-ticket:focus {
  border: none;
  border-radius: 0;
  outline: none;
}
.brick .brick-info .brick-info-detail {
  margin: 5px;
  margin-top: 70px;
  color: white;
  text-align: left;
  text-transform: uppercase;
  font-weight: 800;
}
.brick .brick-info-order {
  background: url("/images/assets/upcoming.png");
  background-size: 100% 100%;
  width: 75px;
  height: 75px;
  opacity: 2;
  position: relative;
}
.brick:hover .brick-info {
  opacity: 1;
  color: white;
}
li.divided {
  width: 227px;
  height: 325px;
  position: relative;
  margin: auto;
}
.divided .divided-image {
  position: absolute;
  margin: auto;
  background-size: 100% 100%;
  width: 227px;
  height: 325px;
}
.divided .divided-info {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  background-size: 100% 100%;
  opacity: 0;
  width: 227px;
  height: 325px;
  /* Safari */
  -moz-transition-duration: .5s;
       transition-duration: .5s;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
}
.divided .divided-info .divided-title {
  font-weight: bold;
  text-align: center;
  color: white;
  font-size: 18px;
  text-decoration: none;
}
.divided .divided-info-button {
  position: relative;
  width: 50%;
  height: 50%;
  top: 27%;
  left: 12%;
}
.divided .divided-info-button .btn-config-watch-trailer {
  width: 172px;
  height: 40px;
  background: url('/images/assets/rounded-rectangle-movie-selected.png') no-repeat;
  border: none;
  color: white;
  display: inline-block;
  margin: 2px;
  line-height: 25px;
}
.divided .divided-info-button .btn-config-buy-ticket {
  width: 172px;
  height: 40px;
  background: url('/images/assets/buy-ticket.png');
  border: none;
  color: white;
  margin: 2px;
  display: inline-block;
  line-height: 25px;
}
.divided .divided-info-button .glyphicon {
  color: white;
}
.divided .divided-info-button .btn-config-watch-trailer:hover {
  background: url('/images/assets/rounded-rectangle-movie-selected-hover.png') no-repeat;
  /* Safari */
  -moz-transition-duration: .5s;
       transition-duration: .5s;
}
.divided .divided-info-button .btn-config-buy-ticket:hover {
  background: url('/images/assets/buy-ticket-hover.png') no-repeat;
  /* Safari */
  -moz-transition-duration: .5s;
       transition-duration: .5s;
}
.divided .divided-info-button .btn-config-watch-trailer:focus {
  border: none;
  border-radius: 0;
  outline: none;
}
.divided .divided-info-button .btn-config-buy-ticket:focus {
  border: none;
  border-radius: 0;
  outline: none;
}
.divided .divided-info .divided-info-detail {
  margin: 5px;
  margin-top: 70px;
  color: white;
  text-align: left;
  text-transform: uppercase;
  font-weight: 800;
}
.divided-info-order {
  background-size: 100% 100%;
  width: 75px;
  height: 75px;
  opacity: 2;
  position: relative;
}
.divided-info-order.nowplaying {
  background-image: url("/images/assets/nowplaying.png");
}
.divided-info-order.upcoming {
  background-image: url("/images/assets/upcoming.png");
}
.divided:hover .divided-info {
  opacity: 1;
}
.circle {
  width: 104px;
  height: 104px;
  position: relative;
  display: inline-block;
  background-size: 100% 100%;
}
.circle .content {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 104px;
  text-align: center;
  width: 104px;
  height: 104px;
  font-size: 25pt;
  font-weight: bold;
}
.circle .circle-label {
  position: absolute;
  bottom: -20px;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 10pt;
  display: block;
  width: 100%;
}
.circle.orange-circle {
  background-image: url('/images/assets/orange.png');
}
.circle.red-circle {
  background-image: url('/images/assets/red.png');
}
.circle.blue-circle {
  background-image: url('/images/assets/blue.png');
}
.circle.green-circle {
  background-image: url('/images/assets/green.png');
}
.circle-article {
  width: 74px;
  height: 74px;
  position: relative;
  display: inline-block;
  background-size: 100% 100%;
}
.circle-article .content {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 74px;
  text-align: center;
  width: 74px;
  height: 74px;
  font-size: 17pt;
}
.circle-article .circle-label {
  position: absolute;
  bottom: -20px;
  text-transform: uppercase;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 7pt;
  display: block;
  width: 100%;
}
.circle-article.orange-circle {
  background-image: url('/images/assets/orange.png');
}
.circle-article.red-circle {
  background-image: url('/images/assets/red.png');
}
.circle-article.blue-circle {
  background-image: url('/images/assets/blue.png');
}
.circle-article.green-circle {
  background-image: url('/images/assets/green.png');
}
.btn-header {
  background: none;
  border: none;
  border-radius: 0;
  margin: 0;
  display: inline-block;
  outline: none;
  color: white;
}
.btn-header:hover {
  color: white;
  border: none;
  outline: none;
  border-radius: 0px;
  background: rgba(0, 174, 239, 0.5);
  -moz-transition-delay: 0s;
       transition-delay: 0s;
  -moz-transition-duration: 0.6s;
       transition-duration: 0.6s;
  -moz-transition-property: all;
  transition-property: all;
  -moz-transition-timing-function: ease-in-out;
       transition-timing-function: ease-in-out;
}
.btn-header:active {
  border: none;
  border-radius: 0px;
  outline: none;
  color: white;
}
.btn-header:focus {
  border: none;
  border-radius: 0;
  outline: none;
  color: white;
}
.btn-control-header {
  background: none;
  border: none;
  border-radius: 0;
  margin-top: 10px;
}
.new:after {
  content: "";
  background-image: url(/images/assets/red_new.png);
  background-size: 30px 30px;
  width: 30px;
  height: 30px;
  display: block;
  float: right;
  word-wrap: break-word;
  margin-top: -4px;
}
header.header {
  display: block;
  position: relative;
  height: 768px;
}
.container.error-container {
  width: 100%;
  height: initial !important;
  background: #333;
}
.container.error-container .info-error {
  margin: 10% 50px;
}
.container.error-container .info-error .title-container {
  color: #fff;
}
.container.error-container .info-error .title-container .error-title {
  line-height: 1;
  margin: 0;
  font-weight: 600;
  font-size: 60px;
}
.container.error-container .info-error .error-message {
  color: #fff;
  margin: 15px 0;
}
.container.error-container .info-error .error-message h4 {
  margin: 0;
  line-height: 16px;
  font-weight: 400;
  font-size: 17px;
}
.container.error-container .info-error .back-to-home {
  /*
			a.btn{
				margin-left: 140px;
				margin-top: 55px;
				&:hover {
				    color: #333;
				    text-decoration: none;
				    color: #337ab7;
				}
				i.fa{
					font-size: 85px;
				}
				span{
					position: absolute;
					margin-top: 10px;
					font-size: 25px;
					font-weight: bold;
					font-family: sans-serif;
					margin-left: -20px;
				    letter-spacing: -3px;
				}
			}
		}*/
}
.container.error-container .image-container {
  float: right;
  width: 600px;
  margin: 25% 0;
}
.container.error-container .image-container img {
  width: 100%;
}
.container.content-footer {
  height: 25%;
}
.container.content-footer .footer {
  background: #000;
  height: 168px;
  position: relative;
  padding: 0 35%;
}
.container.content-footer .footer .main-footer {
  margin-top: 30px;
  width: 500px;
}
.container.content-footer .footer .main-footer .contact-container {
  background: url(/images/assets/cinema-background.png) no-repeat;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 17px;
  padding: 5px;
}
.container.content-footer .footer .main-footer .contact-container .logo-container {
  width: 200px;
}
.container.content-footer .footer .main-footer .contact-container .logo-container img {
  width: 100%;
}
.container.content-footer .footer .main-footer .contact-container .telp-container .place {
  font-size: 20px;
}
.desktop-error {
  color: #fff;
  background: -moz-radial-gradient(bottom ellipse, #1b2735 0, #090a0f 100%);
  background: radial-gradient(ellipse at bottom, #1b2735 0, #090a0f 100%);
  position: fixed;
  height: 100%;
  width: 100%;
}
.desktop-error img.error {
  width: 70%;
  position: absolute;
  left: 15%;
  margin-top: 50px;
}
.desktop-error h1.tittle {
  font-size: 150px;
  font-weight: 700;
  text-shadow: 6px 7px 4px #000;
  margin-top: 150px;
}
.desktop-error h1.page-not-found {
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 5px;
}
.desktop-error .desktop-error-footer {
  position: absolute;
  bottom: 0px;
  height: 27%;
  width: 100%;
  color: #fff;
  background: -moz-radial-gradient(bottom ellipse, #1b2735 0, #0c0e14 100%);
  background: radial-gradient(ellipse at bottom, #1b2735 0, #0c0e14 100%);
}
.desktop-error .desktop-error-footer .col-md-4 {
  padding: 0px 160px 0px 160px;
}
.select-schedule-container {
  color: white;
}
.select-schedule-container .select-theme {
  background: #282828;
  border: 1px solid #555;
  font-size: 20px;
  color: #fff;
  border-radius: 0;
  box-shadow: none;
  font-family: Arial, Helvetica, sans-serif;
}
.select-schedule-container .select-theme .option-theme {
  padding: 5px 10px;
  line-height: 22px;
  text-align: center;
}
.select-schedule-container .select-theme .option-theme:before {
  content: "";
  background-image: url(/images/assets/logo-small.png);
  background-size: 30px 30px;
  width: 30px;
  height: 30px;
  display: block;
  float: left;
  margin-right: 10px;
  word-wrap: break-word;
  margin-top: -4px;
}
.content-schedule-container {
  background: #333;
}
.content-schedule-container .img-container {
  height: 180px;
  width: 130px;
  position: relative;
}
.content-schedule-container .img-container img {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.content-schedule-container .img-container .img-cover {
  background-image: url(/images/assets/cinema-background.png);
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
}
.content-schedule-container .img-container .img-button {
  position: absolute;
  background: url(/images/assets/btn-play.png);
  height: 100px;
  width: 100px;
  display: block;
  top: 22%;
  left: 11%;
  border: 0;
  border-radius: 0;
  outline: 0;
}
.content-schedule-container .img-container:hover .img-cover {
  opacity: 1;
}
.content-schedule-container .content-container {
  height: 180px;
  color: #fff;
  margin-left: 12px;
  padding: 15px;
}
.content-schedule-container .content-container .title {
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #00aeef;
}
.content-schedule-container .content-container .info-container {
  position: relative;
}
.content-schedule-container .content-container .info-container .studio-container {
  float: right;
  display: block;
  width: 120px;
  height: 100px;
  position: relative;
  text-decoration: none;
  color: #fff;
}
.content-schedule-container .content-container .info-container .studio-container .label-studio {
  position: absolute;
  top: 0;
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.content-schedule-container .content-container .info-container .studio-container .number {
  position: absolute;
  bottom: 10px;
  font-size: 65px;
  left: 0;
  width: 100%;
  text-align: center;
  font-family: arial;
  font-weight: bold;
  text-decoration: none;
}
.content-schedule-container .content-container .info-container .studio-container .type {
  position: absolute;
  bottom: 10px;
  font-size: 14px;
  width: 100%;
  text-align: center;
}
.content-schedule-container .content-container .info-container .studio-container:hover {
  background: #00aeef;
  color: #fff !important;
}
.content-schedule-container .content-container .info-container .info {
  display: inline;
  padding-right: 10px;
}
.content-schedule-container .content-container .info-container .font-size-15 {
  font-size: 15px;
}
.content-schedule-container .content-container .info-container .btn-schedule {
  margin-right: 10px;
  border-radius: 0;
  background: #282828;
  border: 1px solid #333;
  font-family: Arial, Helvetica, sans-serif;
}
.content-schedule-container .content-container .info-container .btn-schedule:hover {
  background: #00aeef;
  cursor: pointer;
}
.description .content {
  font-size: 14px;
  color: white;
  padding: 15px 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #333;
  background: #003f56;
}
.description .content.danger {
  background: #560000;
}
.bg-partnership {
  background: url("/images/assets/bg-partnership.png");
  background-size: 100% 100%;
}
.partner {
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  position: relative;
}
.partner .partner-title {
  font-weight: bold;
  font-size: 60px;
}
.partner img {
  width: 100%;
}
.partner .header-image {
  opacity: 0.7;
}
.partner .partner-join {
  position: relative;
  bottom: 330px;
  text-align: center;
}
.partner .grow-business {
  display: inline;
  position: absolute;
  top: 370px;
  width: 100%;
  left: 15px;
}
.partner .grow-business .panel-partnership {
  padding: 5px 10px 10px 65px;
  color: #000;
  background: #fff;
  margin-top: 5%;
  height: 150px;
}
.partner .grow-business .col-md-3 {
  padding: 0;
}
.partner .grow-business .col-md-3 .contact {
  background: #3e24b1;
  width: 100%;
  height: 193px;
  color: white;
  padding: 10px 10px 55px 20px;
}
.partner .grow-business .col-md-3 a.link {
  -webkit-text-decoration-line: none;
     -moz-text-decoration-line: none;
          text-decoration-line: none;
}
.partner .grow-business .col-md-3 a.link .contact:hover {
  background: #2507aa;
  cursor: pointer;
  color: white;
  -moz-transition-duration: 0.5s;
       transition-duration: 0.5s;
}
.partner .grow-business .col-md-3 a.link .contact:hover h3.hover {
  color: white;
  margin-bottom: 20px;
  cursor: pointer;
  -moz-transition-duration: 0.5s;
       transition-duration: 0.5s;
}
.partner .body-partner {
  color: #000;
  background: #fff;
  padding: 20px;
  margin-top: 40px;
}
.partner .body-partner .our-partnership {
  line-height: 5px;
}
.partner .body-partner .our-partnership .offer {
  font-size: 22px;
}
.partner .body-partner .blog {
  font-size: 16px;
  padding: 10px;
  line-height: 30px;
}
.partner .body-partner .img-blog {
  width: 544px;
  height: 365px;
  margin-bottom: 20px;
}
.partner .body-partner .bold-blog {
  font-weight: bold;
  padding: 15px;
  margin-top: 60px;
}
.partner .gallery {
  text-align: center;
}
.partner .gallery .line-bottom {
  width: 79px;
  height: 4px;
}
.partner .gallery .gallery-title {
  font-weight: bold;
}
.partner .img-gallery {
  height: 148px;
}
.partner .gallery-place {
  margin-top: 25px;
  padding: 15px;
}
.partner .gallery-place img {
  width: 100%;
}
.partner .gallery-place img.logo {
  width: 250px;
  padding: 15px;
  margin-left: 35px;
}
.partner .gallery-place img.logo-aurora {
  width: 220px;
  padding: 23px 15px 15px 15px;
  margin-left: 60px;
}
.partner .bold {
  font-weight: bold;
}
.partner .footer {
  background: #222;
  height: 100%;
  width: 80%;
  text-align: center;
  padding: 150px 0;
  margin-bottom: 100px;
}
.partner .footer .info-contact {
  letter-spacing: 5px;
}
.partner .footer a.contact {
  color: #fff;
  letter-spacing: 3px;
}
.confirm-key {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eeeeee+0,eeeeee+100;Grey+Flat */
  background: #eeeeee;
  /* Old browsers */
  background: -moz-linear-gradient(top, #eeeeee 0%, #eeeeee 100%);
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #eeeeee 0%, #eeeeee 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#eeeeee', GradientType=0);
  /* IE6-9 */
  padding: 50px;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 50%;
}
.confirm-key h1 {
  font-weight: bold;
}
.confirm-key .form-control {
  font-size: 50px;
  width: 50%;
  margin: 10px;
}
.confirm-key .btn-success {
  margin: 10px;
}
.confirm-key a.btn.btn-default {
  margin: 10px;
}
.confirm-key .verification {
  border: 1px solid #ccc;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
}
.news-ticker {
  position: relative;
}
.news-ticker .news {
  width: 700px;
  padding: 10px;
  right: 0;
  float: right;
}
.news-ticker .news ul {
  list-style: none;
  padding: 0;
}
.news-ticker .news ul li {
  overflow: hidden;
  padding: 5px 0;
}
.news-ticker .news ul li .img-container {
  display: block;
  float: left;
  width: 150px;
  height: 75px;
}
.news-ticker .news ul li .img-container img {
  width: 150px;
  height: 75px;
}
.news-ticker .news ul li .content {
  width: 540px;
  height: 75px;
  display: block;
  float: left;
  color: #ddd;
  font-size: 12px;
  color: white;
  padding: 0 5px;
}
.news-ticker .news ul li .content a {
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}
.news-ticker .news ul li .content p {
  margin: 0;
  line-height: 13px;
}
.news-article {
  width: 100%;
  height: 100%;
  color: #000000;
}
.news-article .bg-white {
  background: #fafafa;
  color: #333;
  min-height: 1204px;
}
.news-article .bg-gray {
  background: #333;
  height: auto;
}
.news-article .bg-black {
  background: #000;
  color: white;
  padding: 24px;
}
.news-article .sidebar-block > .title {
  color: #fff;
  border-bottom: 1px solid #2d88bacc;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin: 20px 20px 0 20px;
}
.news-article .image-news {
  position: relative;
  margin: 0;
  width: 100%;
  height: 570px;
  background-size: 100%;
}
.news-article .image-news:before {
  bottom: 0;
  content: "";
  display: block;
  height: 50%;
  width: 100%;
  position: absolute;
  z-index: 1;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
}
.news-article .image-news .title {
  position: absolute;
  bottom: 0px;
  left: 15px;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  font-size: 44px;
  font-weight: bold;
  text-align: left;
  margin: 10px 50px 30px 30px;
  text-shadow: 1px 1px #333;
  z-index: 1;
}
.news-article .image-news .title .subtitle {
  font-size: 12px;
  font-weight: normal;
}
.news-article .image-news .share-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 1;
}
.news-article .image-news .share-button span {
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  color: #fff;
  margin-bottom: 10px;
}
.news-article .image-news .share-button a {
  margin: 10px 20px 10px 0px;
}
.news-article .image-news .share-button a img {
  width: 40px;
}
.news-article .article {
  text-align: left;
  padding: 30px 0 30px 30px;
  line-height: 35px;
}
.news-article .article img {
  width: 100%;
}
.news-article .article iframe {
  width: 100% !important;
}
.news-article .social-media .title-social {
  color: #000;
  font-weight: bold;
  text-decoration: underline;
  font-size: 20px;
}
.news-article .social-media .social {
  display: inherit;
  color: #000;
  text-align: left;
}
.news-article .social-media i.fa.fa-twitter-square {
  color: #2e98d6;
  font-size: 45px;
  float: left;
  margin-right: 10px;
}
.news-article .social-media .google-plus {
  margin-top: 10px;
}
.news-article .social-media .google-plus i.fa.fa-google-plus-square {
  color: #d32222;
  font-size: 45px;
  float: left;
  margin-right: 10px;
}
.news-article .social-media .instagram {
  margin-top: 10px;
}
.news-article .social-media .instagram i.fa.fa-instagram {
  color: rgba(184, 6, 238, 0.979);
  font-size: 45px;
  float: left;
  margin-right: 10px;
}
.news-article ul.news-highlight {
  list-style: none;
  padding: 20px;
  margin: 0;
}
.news-article ul.news-highlight li {
  color: white;
  margin-bottom: 10px;
}
.news-article ul.news-highlight li img {
  width: 100%;
  margin-bottom: 5px;
}
.news-article ul.news-highlight li .title {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
}
.news-article ul.news-highlight li .desc {
  font-weight: 400;
  line-height: 18px;
}
.news-article ul.news-highlight li .link {
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  padding: 10px 5px;
  border: 3px solid #fff;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 30px;
  display: block;
  width: 150px;
  text-align: center;
}
.news-article ul.news-highlight li .link:hover {
  background: white;
  color: #333;
  text-decoration: none;
}
.content-news-list {
  width: 100%;
  height: 100%;
  background: #fff;
  padding-bottom: 20px;
}
.content-news-list .image-header {
  position: relative;
  margin: 0;
  width: 100%;
  height: 340px;
  background-size: 100%;
  background: #333;
}
.content-news-list .image-header:before {
  bottom: 0;
  content: "";
  display: block;
  height: 50%;
  width: 100%;
  position: absolute;
  z-index: 1;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
}
.content-news-list .image-header img {
  background-size: 100%;
  width: 70%;
}
.content-news-list .image-header .image-side {
  position: absolute;
  top: 0;
  right: 0;
  width: 30%;
  padding: 20px;
  z-index: 1;
}
.content-news-list .image-header .image-side .title-header {
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  color: #fff;
  font-size: 25px;
  font-weight: 700;
  text-align: left;
  text-shadow: 1px 1px #333;
}
.content-news-list .image-header .image-side .title-header .subtitle-header {
  font-size: 12px;
  text-transform: none;
  font-weight: normal;
}
.content-news-list .image-header .image-side .content-header {
  color: #fff;
  text-align: justify;
  font-size: 12px;
  font-family: roboto, sans-serif;
}
.content-news-list .button-slide {
  position: absolute;
  bottom: 20px;
  right: 30px;
  overflow: hidden;
}
.content-news-list .button-slide .btn-ctrl {
  margin-right: 5px;
  font-size: 14px;
  border-radius: 2px;
  padding: 0px;
  color: #fff;
  text-decoration: none;
  /* font-weight: 700; */
}
.content-news-list .button-slide .btn-ctrl:hover {
  color: #fff;
  background: rgba(53, 53, 53, 0.055);
}
.content-news-list .button-slide .bx-prev {
  float: left;
  margin-right: 5px;
  font-size: 14px;
  border-radius: 2px;
  padding: 0px;
  color: #fff;
  text-decoration: none;
  /* font-weight: 700; */
}
.content-news-list .button-slide .bx-prev:hover {
  color: #fff;
  background: rgba(53, 53, 53, 0.055);
}
.content-news-list .button-slide .bx-next {
  float: right;
  margin-right: 5px;
  font-size: 14px;
  border-radius: 2px;
  padding: 0px;
  color: #fff;
  text-decoration: none;
  /* font-weight: 700; */
}
.content-news-list .button-slide .bx-next:hover {
  color: #fff;
  background: rgba(53, 53, 53, 0.055);
}
.content-news-list .newslist {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  list-style: none;
  padding: 0px 30px;
}
.content-news-list .newslist .image-content {
  width: 360px;
  height: 270px;
  padding: 20px;
}
.content-news-list .newslist .image-content img {
  width: 100%;
  height: 100%;
  transition: -webkit-transform .2s;
  -moz-transition: transform .2s, -moz-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s, -moz-transform .2s;
  /* Animation */
}
.content-news-list .newslist .image-content img:hover {
  -ms-transform: scale(1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1);
  /* Safari 3-8 */
  -moz-transform: scale(1.1);
       transform: scale(1.1);
}
.content-news-list .newslist .title-content {
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  padding: 0px 20px 0px 20px;
}
.content-news-list .newslist .title-content .title {
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  overflow: hidden;
}
.content-news-list .newslist .content {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  padding: 5px 20px 10px 20px;
  line-height: 20px;
  width: 360px;
  height: 185px;
  overflow: hidden;
}
.content-news-list .link {
  text-transform: uppercase;
  font-weight: 700;
  text-decoration: none;
  color: #fff;
  background: #333;
  font-family: 'Roboto', sans-serif;
  padding: 7px;
  border: 3px solid #fff;
  cursor: pointer;
  margin-top: 5px;
  margin-bottom: 10px;
  display: block;
  width: 130px;
  text-align: center;
  font-size: 11px;
}
.content-news-list .link:hover {
  background: white;
  color: #333;
  text-decoration: none;
  border: 3px solid #333;
}
.content-news-list .highlight-topic {
  font-weight: 700;
  background: #0e8fe6;
  color: #fff;
  font-size: 16px;
  padding: 10px;
  margin: 20px 25px 0 50px;
  width: 200px;
  text-align: center;
  font-family: Roboto, sans-serif;
}
.content-news-list .line {
  margin: 0 50px 10px 50px;
  border-bottom: 3px solid #0e8fe6;
}
.content-news-list .image-other {
  margin: 0pc 0px 10px 20px;
  width: 100%;
  height: 100%;
}
.content-news-list .image-other img {
  width: 100%;
  height: 100%;
}
.content-news-list .other-title {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: bold;
}
.content-news-list .other-content {
  width: 60%;
  font-family: 'Roboto', sans-serif;
}
.content-news-list .other-content p {
  font-size: 13px;
  margin-bottom: 2px;
}
.content-news-list .other-content .info {
  font-size: 12px;
}
.studio {
  position: relative;
  top: 100px;
  color: white;
  background: #333;
}
.studio .header-blue {
  background-image: url(/images/assets/header-blue.png);
  background-repeat: no-repeat;
  background-size: 70%;
  height: 300px;
  color: #000;
  background-color: #efefef;
}
.studio .header-blue .studio-title-container {
  margin: 0;
  padding-top: 15px;
}
.studio .header-blue .studio-title-container .content {
  position: relative;
  top: 40px;
}
.studio .header-blue .studio-title-container .studio-title {
  font-size: 16px;
  font-weight: bold;
  color: white;
  margin-right: 5px;
}
.studio .header-blue .studio-title-container .studio-text {
  font-size: 55px;
  font-weight: bold;
  margin: 0;
  color: white;
}
.studio .header-blue .studio-title-container .logo-title {
  width: 17%;
  display: block;
  margin-top: 15px;
}
.studio .header-blue .studio-title-container .studio-number {
  text-align: center;
  line-height: 1.1;
}
.studio .header-blue .studio-title-container .studio-number .number {
  font-size: 200px;
  font-family: arial;
  font-weight: 700;
}
.studio .header-blue .studio-title-container .studio-number .text {
  line-height: 0;
  font-weight: 700;
  font-size: 40px;
  display: block;
}
.studio .header-green {
  background-image: url(/images/assets/header-green.png);
  background-repeat: no-repeat;
  background-size: 70%;
  height: 300px;
  color: #000;
  background-color: #13498c;
}
.studio .header-green .studio-title-container {
  margin: 0;
  padding-top: 15px;
}
.studio .header-green .studio-title-container .content {
  position: relative;
  top: 40px;
}
.studio .header-green .studio-title-container .studio-title {
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
}
.studio .header-green .studio-title-container .studio-text {
  font-size: 55px;
  font-weight: bold;
  margin: 0;
}
.studio .header-green .studio-title-container .logo-title {
  width: 17%;
  display: block;
  margin-top: 15px;
}
.studio .header-green .studio-title-container .studio-number {
  text-align: center;
  line-height: 1.1;
}
.studio .header-green .studio-title-container .studio-number .number {
  font-size: 200px;
  font-family: arial;
  font-weight: 700;
  background-color: #000;
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: #fff 3px 3px 0;
}
.studio .header-green .studio-title-container .studio-number .text {
  line-height: 0;
  font-weight: 700;
  font-size: 40px;
  display: block;
}
.studio .header-purple {
  background-image: url(/images/assets/header-purple.png);
  background-repeat: no-repeat;
  background-size: 70%;
  height: 300px;
  background-position: 100%;
  color: #fff;
  background-color: #333;
}
.studio .header-purple .studio-title-container {
  margin: 0;
  padding-top: 15px;
}
.studio .header-purple .studio-title-container .content {
  position: relative;
  top: 40px;
}
.studio .header-purple .studio-title-container .studio-title {
  font-size: 16px;
  font-weight: bold;
  margin-right: 5px;
}
.studio .header-purple .studio-title-container .studio-text {
  font-size: 55px;
  font-weight: bold;
  margin: 0;
}
.studio .header-purple .studio-title-container .logo-title {
  width: 17%;
  display: block;
  margin-top: 15px;
}
.studio .header-purple .studio-title-container .studio-number {
  text-align: center;
  line-height: 1.1;
}
.studio .header-purple .studio-title-container .studio-number .number {
  font-size: 200px;
  font-family: arial;
  font-weight: 700;
  background-color: #333;
  -webkit-background-clip: text;
  color: transparent;
  text-shadow: #fff 3px 3px 0;
}
.studio .header-purple .studio-title-container .studio-number .text {
  line-height: 0;
  font-weight: 700;
  font-size: 40px;
  display: block;
}
.studio .nav-studio {
  background: #343a40;
  margin: 0;
}
.studio .nav-studio .navbar-nav {
  list-style: none;
  font-size: 20px;
  padding: 10px;
}
.studio .nav-studio .nav-link {
  list-style: none;
  color: white;
  padding: 15px;
  font-size: 17px;
}
.studio .nav-studio a:hover {
  text-decoration: none;
  color: #ccc;
  cursor: pointer;
}
.studio .studio-content {
  background: #efefef;
  padding: 15px;
}
.studio .studio-content .studio-content-image {
  width: 100%;
  margin: 20px 0;
}
.studio .studio-content .studio-content-text {
  color: #000;
  margin-top: 20px;
}
.studio .studio-content .studio-content-text .studio-content-title {
  font-size: 30px;
  font-weight: bold;
  display: block;
}
.studio .studio-content .studio-content-text .line {
  display: block;
  margin: 10px 0;
}
.studio .studio-content .studio-content-text .line-right {
  margin: 10px 0;
}
.studio .studio-content .studio-content-text .text-left {
  text-align: left;
  font-size: 1.7em;
  line-height: 40px;
}
.studio .studio-content .studio-content-text .text-right {
  text-align: right;
  font-size: 1.7em;
  line-height: 40px;
}
.studio .title-gallery {
  font-size: 30px;
  font-weight: bolder;
}
.studio .line-gallery {
  display: block;
  margin: 10px auto;
}
.studio .popup {
  border: none;
  width: 263px;
  min-height: 198px;
  max-height: 198px;
  margin: 10px;
  overflow: hidden;
}
.studio .popup .pict-gallery {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin: 10px;
}
.studio .spec {
  background: #fff;
  color: #000;
  margin: 30px 0 0 0;
}
.img-header-help {
  background: url(/images/assets/bg-partnership.png);
  background-size: 100% ;
  padding-bottom: 5%;
}
.header-help {
  padding-bottom: 11%;
}
.header-help .title-header-help {
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  font-family: sans-serif;
  margin: 0 auto;
  width: 550px;
  height: 60px;
  margin-top: 12%;
}
.header-help .search-input-help {
  width: 600px;
  margin: 0 auto;
  margin-top: 1%;
  position: relative;
  display: table;
  border-collapse: separate;
}
.list-help {
  font-family: sans-serif;
  margin: 0;
}
.body-help {
  background: #fff;
  color: #000;
  padding: 40px 60px;
  margin-bottom: 5%;
}
.body-help [aria-expanded="false"] .plus-icon {
  display: block;
}
.body-help [aria-expanded="false"] .minus-icon {
  display: none;
}
.body-help [aria-expanded="true"] .plus-icon {
  display: none;
}
.body-help [aria-expanded="true"] .minus-icon {
  display: block;
}
.body-help .question-container {
  border-radius: 3px;
  margin: 1px 0;
  padding: 5px;
}
.body-help .question-container i {
  position: absolute;
  color: #00aeef;
  margin-top: 3px;
  font-size: 15px;
}
.body-help .question-container .question-container[aria-expanded] {
  height: 240px;
}
.body-help .question-container .question {
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  min-height: 20px;
  font-weight: bold;
  margin-left: 20px;
  border-bottom: 1px solid #ccc;
}
.body-help .well {
  background: #efefef;
  border-radius: 0;
  border: 1px solid #ccc;
  padding: 20px;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
}
html {
  height: 100%;
}
body {
  height: 100%;
  background: -moz-radial-gradient(bottom ellipse, #1b2735 0, #090a0f 100%);
  background: radial-gradient(ellipse at bottom, #1b2735 0, #090a0f 100%);
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  font-weight: 100;
  background-attachment: fixed;
}
#myModal {
  padding: 0 !important;
}
.container {
  height: 100%;
}
.background-video {
  position: fixed;
}
.ytplayer-container {
  position: absolute;
  top: 0;
  z-index: -1;
}
.parallax-slider {
  -webkit-filter: brightness(100%);
  /* Safari */
  filter: brightness(100%);
}
.no-border {
  border: none;
}
.no-padding {
  padding: 0;
}
.color-black {
  color: black;
}
h1.title-xxl {
  padding: 0;
  font-size: 62px;
  font-weight: 800;
  font-family: sans-serif;
}
h2.title-xl {
  padding: 0;
  font-weight: 800;
}
h3.title-lg {
  padding: 0;
  font-weight: 300;
}
h4.title-md {
  padding: 0;
  font-weight: 600;
}
.glyphicon-search {
  color: white;
}
.glyphicon-menu-hamburger {
  color: white;
}
.align-center {
  text-align: center;
}
.align-justify {
  text-align: justify;
}
:-webkit-full-screen #background-video {
  font-size: 20px;
  width: 100%;
}
:-moz-full-screen #background-video {
  font-size: 20px;
  width: 100%;
}
.social-media {
  position: relative;
  color: #ddd;
  border: 1px solid #fff;
  padding: 15px;
  margin-bottom: 10px;
}
.social-media .item {
  margin: 5px 0;
}
.social-media .title {
  text-transform: uppercase;
  font-size: 22px;
}
.unsubscribe {
  position: relative;
  top: 200px;
}
.unsubscribe .container {
  background: white;
  height: 100px;
  padding: 20px;
}
.unsubscribe .text {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
.unsubscribe .btn-unsubscribe {
  width: 55%;
}
